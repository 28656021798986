.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--color-background);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: all var(--transition-duration) ease;
  padding: 10px 0;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: var(--color-primary);
  text-decoration: none;
  transition: transform var(--transition-duration) ease;
}

.logo-image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.logo-half {
  font-size: 30px;
  color: var(--color-primary);
  transition: color var(--transition-duration) ease;
}

.logo-price {
  font-size: 30px;
  transition: color var(--transition-duration) ease;
}

.logo-grocery {
  font-size: 22px;
  color: var(--color-secondary);
  font-weight: 100;
}

.logo:hover .logo-half {
  color: var(--woolworths-color);
}

.logo:hover .logo-price {
  color: var(--coles-color);
}

.main-nav {
  display: flex;
  gap: 30px;
}

.nav-link {
  font-size: 16px;
  color: var(--color-text);
  text-decoration: none;
  font-weight: 500;
  transition: all var(--transition-duration) ease;
  position: relative;
  padding: 5px 0;
}

.nav-link:hover {
  color: var(--color-accent);
}

.user-actions {
  display: flex;
  align-items: center;
  padding-right: 5px;
  gap: 5px;
}

.heart-icon {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-error);
  transition: color var(--transition-duration) ease,
    transform var(--transition-duration) ease;
}

.heart-icon:hover {
  transform: scale(1.1);
}

.favorites-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: var(--color-accent);
  color: var(--color-text);
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 12px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform var(--transition-duration) ease;
}

.heart-icon:hover .favorites-count {
  transform: scale(1.1);
}

.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text);
}

@media (max-width: 600px) {
  .header {
    padding: 10px 0;
  }

  .header-content {
    padding: 10px;
  }

  .logo-half {
    font-size: 2rem;
  }
  .logo-price {
    font-size: 2rem;
  }

  .logo-grocery {
    font-size: 1.4rem;
    padding-right: 15px;
  }

  .main-nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--color-background);
    padding: 20px;
  }

  .main-nav.show {
    display: flex;
  }

  .mobile-menu-toggle {
    display: block;
    padding: 5px 0 5px 10px;
    margin: 5px 0;
  }
}
