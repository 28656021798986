.price-history-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(8px);
}

.price-history-content {
  background-color: var(--color-surface);
  border-radius: 24px;
  padding: 32px;
  width: 92%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(255, 255, 255, 0.1);
  animation: slideUp 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.price-history-title {
  font-size: 2rem;
  color: var(--color-primary);
  margin-bottom: 28px;
  padding-right: 48px;
  font-weight: 800;
  letter-spacing: -0.02em;
}

.price-history-subtitle {
  font-size: 1.3rem;
  color: var(--color-secondary);
  display: block;
  margin-top: 0.85em;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.price-summary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
  margin: 32px 0;
  padding: 32px;
  background: var(--color-background);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(4px);
}

@media (max-width: 480px) {
  .price-history-content {
    padding: 20px;
    border-radius: 20px;
    width: 95%;
  }

  .price-history-title {
    font-size: 1.4rem;
    margin-bottom: 16px;
    padding-right: 24px;
  }

  .price-history-subtitle {
    font-size: 1.1rem;
  }

  .price-summary {
    padding: 20px;
    gap: 16px;
    margin: 24px 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
