.footer {
  background-color: var(--color-background);
  padding: 24px;
  text-align: center;
  font-family: var(--font-primary);
  border-top: 1px solid #e7e7e7;
}
.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copyright {
  font-size: 0.9rem;
  color: var(--color-secondary);
  margin-bottom: 1rem;
  text-align: center;
}

.footer-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.footer-nav a {
  color: var(--color-primary);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.footer-nav a:hover {
  color: var(--color-accent);
}

.disclaimer {
  font-size: 0.8rem;
  color: var(--color-secondary);
  text-align: center;
  max-width: 100%;
  line-height: 1.4;
  margin-top: 1rem;
}
