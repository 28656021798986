.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text);
  transition: color 0.3s ease, transform 0.3s ease;
  opacity: 0.8;
  display: flex;
}

.close-button:hover {
  color: var(--color-primary);
  transform: rotate(90deg);
  opacity: 1;
}
