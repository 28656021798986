@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

:root {
  --color-primary: #1a1f36;
  --color-secondary: #64748b;
  --color-accent: #fbbf24;
  --color-background: #f1f5f9;
  --color-surface: #ffffff;
  --color-text: #0f172a;
  --color-success: #10b981;
  --color-error: #ef4444;
  --color-warning: #f59e0b;
  --color-info: #3b82f6;
  --coles-color: #ff3333;
  --woolworths-color: #00a000;
  --font-primary: 'Space Grotesk', sans-serif;
  --font-secondary: 'Manrope', sans-serif;
  --transition-duration: 0.3s;
  --transition-timing: cubic-bezier(0.16, 1, 0.3, 1);
}

/* Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Base styles */
body {
  font-family: var(--font-primary);
  font-size: 16px;
  line-height: 1.5;
  color: var(--color-text);
  background-color: var(--color-background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  font-weight: 300;
}

/* Responsive typography */
h1 {
  font-size: clamp(2rem, 5vw, 3rem);
}
h2 {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
}
h3 {
  font-size: clamp(1.25rem, 3vw, 2rem);
}
h4 {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
}
h5 {
  font-size: clamp(0.875rem, 2vw, 1.25rem);
}
h6 {
  font-size: clamp(0.75rem, 1.5vw, 1rem);
}

/* Global flex utilities */
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

/* Global transitions */
.transition-all {
  transition: all var(--transition-duration) ease-in-out;
}

.transition-transform {
  transition: transform var(--transition-duration) ease-in-out;
}

/* Global animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn var(--transition-duration) ease-in-out;
}
.slide-in {
  animation: slideInFromBottom var(--transition-duration) ease-in-out;
}

/* Print styles */
@media print {
  body {
    font-size: 12pt;
    line-height: 1.4;
    color: var(--color-text);
    background: var(--color-surface);
  }

  a {
    color: var(--color-text);
    text-decoration: underline;
  }

  .no-print {
    display: none;
  }
}
