.favorites-tabs {
  display: flex;
  gap: 24px;
  /* margin: 5px 0; */
  padding: 20px 0px 0 0;
}

.favorites-tab {
  background: none;
  border: none;
  color: var(--color-secondary);
  font-size: 18px;
  padding: 16px 24px;
  position: relative;
  transition: all 0.2s ease;
  font-weight: 400;
  cursor: pointer;
}

.favorites-tab::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--color-accent);
  transform: scaleX(0);
  transition: transform 0.2s ease;
}

.favorites-tab.active {
  color: var(--color-primary);
}

.favorites-tab.active::after {
  transform: scaleX(1);
}

.tab-count {
  position: absolute;
  top: 4px;
  right: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  border-radius: 10px;
  background: var(--color-accent);
  color: var(--color-primary);
  font-size: 12px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .favorites-tabs {
    padding: 20px 0;
    margin: 0;
  }

  #thisWeekFavorites {
    padding: 25px 20px 20px 0;
  }

  #allTimeFavorites {
    padding: 20px 35px 20px 20px;
  }

  .tab-count {
    right: 25px;
  }
}
