.product-card {
  position: relative;
  background-color: var(--color-surface);
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.product-image-container {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  padding: 10px;
  flex: 0 0 auto;
}

.product-image {
  width: 100%;
  height: 140px;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
}

.product-card:hover .product-image {
  transform: scale(1.05);
}

.savings-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--color-accent);
  color: var(--color-primary);
  padding: 4px 8px;
  border-radius: 24px;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  transform: rotate(-5deg);
  overflow: hidden;
  z-index: 2;
}

.savings-badge:hover {
  transform: rotate(0deg) scale(1.05);
}

.brand-logo {
  position: absolute;
  bottom: 10px;
  left: 15px;
  width: 50px;
  height: auto;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  padding: 2px;
}

.product-info-container {
  background-color: var(--color-background);
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.product-card .product-name {
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: 300;
  color: var(--color-primary);
  margin-bottom: 5px;
}

.price-container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 0.5rem;
}

.product-card .current-price,
.product-card .original-price {
  margin: 5px 0;
}

.product-card .current-price {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--color-primary);
}

.price-and-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.product-card .original-price {
  color: var(--color-secondary);
  -webkit-text-decoration: line-through solid rgb(108, 108, 108);
  -moz-text-decoration: line-through solid rgb(108, 108, 108);
  text-decoration: line-through solid rgb(108, 108, 108);
  text-decoration-thickness: 1px;
  text-decoration-skip-ink: none;
}

.price-per-unit {
  font-size: 0.8rem;
  color: var(--color-secondary);
  margin: 0;
}

.product-actions {
  display: flex;
  gap: 5px;
}

.favorite-button,
.price-history-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: var(--color-primary);
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.favorite-button:hover,
.price-history-button:hover {
  transform: scale(1.3);
}

@media (max-width: 767px) {
  .product-image {
    height: 160px;
  }
  .savings-badge {
    font-size: 0.6rem;
  }

  .product-card .product-name {
    font-size: 1rem;
    line-height: 1.2;
  }
}

@media (min-width: 900px) {
  .product-image {
    height: 180px;
  }
  .product-card .product-name {
    font-size: 1.1rem;
  }
  .product-card .current-price {
    font-size: 1.1rem;
  }
}
