.favorites-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.favorites-modal {
  background-color: var(--color-background);
  border-radius: 16px;
  width: 95%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  animation: slideUp 0.2s ease-out;
}

@keyframes slideUp {
  from {
    transform: translateY(8px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.favorites-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 8px 24px;
  position: relative;
}

.favorites-content {
  overflow-y: auto;
  padding: 10px;
}

.no-favorites {
  text-align: center;
  color: var(--color-secondary);
  font-style: italic;
  padding: 40px 20px;
  font-size: 1.1em;
}

.favorites-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 2fr));
  gap: 5px;
}

.favorites-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.favorites-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
